<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
        class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">
            {{ isUpdate? 'Cập nhật' : 'Thêm mới' }} ca làm việc
          </h2>
          <validation-observer  ref="workShiftSetting">
            <b-row>
              <b-col class="col-md-12 col-12 mt-2">
                <h4>Cài đặt</h4>
                <b-row>
                  <b-col class="col-md-6 col-12 mt-1">
                    <div class="form-group">
                      <validation-provider
                          #default="{ errors }"
                          name="Tên nhóm"
                          rules="required"
                      >
                      <label
                        class="label-add-work-shift"
                        for="address"
                      >Tên nhóm<span class="text-danger"> (*)</span></label>
                      <input
                        id="address"
                        v-model="groupName"
                        rules="required"
                        type="text"
                        class="form-control"
                        placeholder="Nhập tên nhóm"
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-if="!isUpdate">
                      <feather-icon
                        icon="PlusIcon"
                        svg-classes="h-5 w-5 hover:text-danger cursor-pointer"
                        @click="addNewRecord(indexWorkshift)"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div
                      v-for="(item, index) in workShiftSetting"
                      :key="index"
                      class="mt-2"
                      style="border-bottom: 1px solid #10163a;"
                    >
                      <b-row class="col-md-12 col-12 mt-1">
                        <b-col class="col-md-4 col-12">
                          <div class="form-group">
                            <validation-provider
                                #default="{ errors }"
                                name="Tên ca"
                                rules="required"
                            >
                            <label
                              class="label-add-work-shift"
                              for="address"
                            >Tên ca<span class="text-danger"> (*)</span></label>
                            <input
                              id="nameW"
                              v-model="item.name"
                              rules="required"
                              type="text"
                              class="form-control"
                              placeholder="Nhập tên ca"
                            >
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-col>
                        <b-col class="col-md-6 col-12 ">
                          <validation-provider
                              #default="{ errors }"
                              name="Ngày làm việc"
                              rules="required"
                          >
                          <label
                            class="label-add-work-shift"
                            for="address"
                          >Ngày làm việc<span class="text-danger"> (*)</span></label>
                          <v-select
                            v-model="item.workDayOfWeekObj"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="label"
                            :options="workDayOfWeekOptions"
                          />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="col-md-12 col-12 mt-1">
                        <b-col
                          class="col-md-2 col-12"
                          md="2"
                          xl="3"
                        >
                          <validation-provider
                              #default="{ errors }"
                              name="Thời gian bắt đầu"
                              rules="required"
                          >
                            <b-form-group class="mb-0">
                          <label
                            class="label-add-work-shift"
                          >Thời gian bắt đầu<span class="text-danger"> (*)</span></label>
                            <v-select
                              v-model="item.startHour"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="label"
                              :options="timeSpans"
                              placeholder="Chọn thời gian bắt đầu"
                              :reduce="(option) => option.value"
                              @change="startHour => updateTimeSpan(item, startHour, 'startHour')"
                              @input="checksChangeTimeS"
                            >
                              <span slot="no-options">Không có dữ liệu.</span>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          class="col-md-2 col-12"
                          md="2"
                          xl="3"
                        >
                          <validation-provider
                              #default="{ errors }"
                              name="Thời gian kết thúc"
                              rules="required"
                          >
                            <b-form-group class="mb-0">
                          <h6>Thời gian kết thúc<span class="text-danger"> (*)</span></h6>
                            <v-select
                              v-model="item.endHour"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="label"
                              :options="timeSpans"
                              placeholder="Chọn thời gian kết thúc"
                              :reduce="(option) => option.value"
                              @change="endHour => updateTimeSpan(item, endHour, 'endHour')"
                              @input="checksChangeTimeE"
                            >
                              <span slot="no-options">Không có dữ liệu.</span>
                            </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col class="col-md-2 col-12">
                          <div class="form-group">
                            <label
                              class="label-add-work-shift"
                              for="bufferTime"
                            >Linh động</label>
                            <input
                              id="bufferTime"
                              v-model="item.bufferTime"
                              rules="required"
                              type="number"
                              class="form-control"
                              placeholder="Nhập thời gian linh động"
                            >
                          </div>
                        </b-col>
                        <b-col class="col-md-2 col-12 mt-2">
                          <div class="form-group">
                            <b-form-checkbox
                              v-model="item.endInNextDay"
                              class="label-add-work-shift"
                              style="margin-top: 10px;"
                            > Làm việc qua ngày
                            </b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col
                          class="col-md-2 col-12 mt-2"
                          style="line-height: 35px;"
                        >
                          <feather-icon
                            v-if="!isUpdate"
                            class="mr-3"
                            icon="PlusIcon"
                            svg-classes="h-5 w-5 hover:text-danger cursor-pointer"
                            @click="addNewRecord(index + 1)"
                          />
                          <feather-icon
                            v-if="!isUpdate"
                            icon="TrashIcon"
                            svg-classes="h-5 w-5 hover:text-danger cursor-pointer"
                            @click="confirmDeleteRecord(index)"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="col-md-12 col-12 mt-1">
                        <b-col class="col-md-3 col-12">
                          <div class="form-group">
                            <label
                              class="label-add-work-shift"
                              for="bufferTime"
                            >Cho phép check in trước(phút)</label>
                            <input
                              id="allowCheckInBefore"
                              v-model="item.allowCheckInBefore"
                              rules="required"
                              type="number"
                              class="form-control"
                              placeholder="Nhập thời gian linh động"
                            >
                          </div>
                        </b-col>
                        <b-col class="col-md-3 col-12">
                          <div class="form-group">
                            <label
                              class="label-add-work-shift"
                              for="bufferTime"
                            >Cho phép check in sau(phút)</label>
                            <input
                              id="allowCheckInAfter"
                              v-model="item.allowCheckInAfter"
                              rules="required"
                              type="number"
                              class="form-control"
                              placeholder="Nhập thời gian linh động"
                            >
                          </div>
                        </b-col>
                        <b-col class="col-md-3 col-12">
                          <div class="form-group">
                            <label
                              class="label-add-work-shift"
                              for="bufferTime"
                            >Cho phép check out trước(phút)</label>
                            <input
                              id="allowCheckOutBefore"
                              v-model="item.allowCheckOutBefore"
                              rules="required"
                              type="number"
                              class="form-control"
                              placeholder="Nhập thời gian linh động"
                            >
                          </div>
                        </b-col>
                        <b-col class="col-md-3 col-12">
                          <div class="form-group">
                            <label
                              class="label-add-work-shift"
                              for="bufferTime"
                            >Cho phép check out sau(phút)</label>
                            <input
                              id="allowCheckOutAfter"
                              v-model="item.allowCheckOutAfter"
                              rules="required"
                              type="number"
                              class="form-control"
                              placeholder="Nhập thời gian linh động"
                            >
                          </div>
                        </b-col>
                      </b-row>
                      <div class="vx-row md:mb-1 mb-2">
                        <br>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                  v-if="!isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addWorkShift"
                >
                  Thêm mới
                </b-button>
                <b-button
                  v-if="isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addWorkShift"
                >
                  Cập nhật
                </b-button>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  :to="{ name: 'manage-work-shift-branch'}"
                  variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utils from './utils'

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkChangeTimeS: false,
      checkChangeTimeE: false,
      groupName: null,
      required,
      dir: 'rtl',
      isUpdate: false,
      indexWorkshift: 0,
      workShiftSetting: [],
      workDayOfWeekOptions: [
        { value: 2, label: 'Thứ 2' },
        { value: 3, label: 'Thứ 3' },
        { value: 4, label: 'Thứ 4' },
        { value: 5, label: 'Thứ 5' },
        { value: 6, label: 'Thứ 6' },
        { value: 7, label: 'Thứ 7' },
        { value: 1, label: 'Chủ nhật' },
      ],
      timeSpans: [],
      idWorkShift: null,
    }
  },
  computed: {
  },
  created() {
    const timeDuration = 30; const timeSpans = []
    for (let i = 0; i < 24 * 60; i += timeDuration) {
      timeSpans.push({ value: i, label: utils.minuteToHour(i) })
    }
    this.timeSpans = timeSpans
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idWorkShift = parameters.id
      this.getInfoWorkShift()
    }
  },
  methods: {
    getInfoWorkShift() {
      let id = this.$route.query.id
      if (!id) {
        return
      }
      const that = this
      this.$crm.get(`work-shift/organization/find-by-id/${this.idWorkShift}`).then(response => {
        const workShiftSetting = response.data
        workShiftSetting.endInNextDay = true ? workShiftSetting.endInNextDay === 1 : workShiftSetting.endInNextDay === 0
        workShiftSetting.startHour = this.timeSpans.find(timeSpan => timeSpan.value === workShiftSetting.startHour).label
        workShiftSetting.endHour = this.timeSpans.find(timeSpan => timeSpan.value === workShiftSetting.endHour).label
        workShiftSetting.workDayOfWeekObj = this.workDayOfWeekOptions.filter(workDay => workShiftSetting.workDayOfWeek.includes(workDay.value))
        if (workShiftSetting) {
          this.isUpdate = true
          this.groupName = workShiftSetting.groupName
        }
        that.workShiftSetting = [workShiftSetting]
        const result = this.workShiftSetting
        this.workShiftSetting.workDayOfWeekObj = result.workDayOfWeek.map(el => {
          const object = {}
          if (el === 1) object.label = 'Chủ nhật'; object.value = el
          if (el === 2) object.label = 'Thứ hai'; object.value = el
          if (el === 3) object.label = 'Thứ ba'; object.value = el
          if (el === 4) object.label = 'Thứ tư'; object.value = el
          if (el === 5) object.label = 'Thứ năm'; object.value = el
          if (el === 6) object.label = 'Thứ sáu'; object.value = el
          if (el === 7) object.label = 'Thứ bảy'; object.value = el
          return object
        })
      })
    },
    addWorkShift() {
      this.$refs.workShiftSetting.validate().then(success => {
        let checkForm = true
        if (!success) {
          checkForm = false
        }
        if (success) {
          if (this.isUpdate && this.checkChangeTimeS === false && this.checkChangeTimeE === false) {
            this.workShiftSetting[0].startHour = this.timeSpans.find(timeSpan => timeSpan.label === this.workShiftSetting[0].startHour).value
            this.workShiftSetting[0].endHour = this.timeSpans.find(timeSpan => timeSpan.label === this.workShiftSetting[0].endHour).value
          }
          if (this.isUpdate && this.checkChangeTimeS === true && this.checkChangeTimeE === false) {
            this.workShiftSetting[0].endHour = this.timeSpans.find(timeSpan => timeSpan.label === this.workShiftSetting[0].endHour).value
          }
          if (this.isUpdate && this.checkChangeTimeE === true && this.checkChangeTimeS === false) {
            this.workShiftSetting[0].startHour = this.timeSpans.find(timeSpan => timeSpan.label === this.workShiftSetting[0].startHour).value
          }
          let isCheckValidate = true
          this.workShiftSetting.forEach(item => {
            if (item.workDayOfWeekObj) {
              item.workDayOfWeek = item.workDayOfWeekObj.map(workDay => workDay.value)
              item.endInNextDay = item.endInNextDay ? 1 : 0
              item.groupName = this.groupName
              if (item.startHour >= item.endHour && item.endInNextDay === 0) isCheckValidate = false
            }
          })
          if (!checkForm) return
          if (!isCheckValidate) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi !',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu.',
              },
            })
            return
          }
          const url = !this.isUpdate ? 'work-shift/organization/create' : `work-shift/organization/update/${this.idWorkShift}`
          this.$crm.post(url, !this.isUpdate ? this.workShiftSetting : this.workShiftSetting[0]).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: this.isUpdate ? 'Cập nhật ca làm việc thành công' : 'Tạo ca làm việc thành công',
              },
            })
            this.$router.push({
              name: 'manage-work-shift-branch',
            })
          })
        }
      })
    },
    addNewRecord(index) {
      this.indexWorkshift += 1
      this.workShiftSetting.splice(index, 0, {})
    },
    confirmDeleteRecord(index) {
      this.selectedWorkShiftId = index
      this.$swal({
        title: 'Xác nhận xóa',
        text: 'Bạn có chắc là muốn xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord()
        }
      })
    },
    deleteRecord() {
      this.workShiftSetting.splice(this.selectedWorkShiftId, 1)
    },
    checksChangeTimeS() {
      this.checkChangeTimeS = true
    },
    checksChangeTimeE() {
      this.checkChangeTimeE = true
    },
    updateTimeSpan(setting, hour, type) {
      setting[type] = hour.value
      setting[`${type}String`] = hour.value
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manager-workShift';
</style>
