import { render, staticRenderFns } from "./WorkShiftInformation.vue?vue&type=template&id=041c8b21"
import script from "./WorkShiftInformation.vue?vue&type=script&lang=js"
export * from "./WorkShiftInformation.vue?vue&type=script&lang=js"
import style0 from "./WorkShiftInformation.vue?vue&type=style&index=0&id=041c8b21&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports